@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom animations */
@keyframes slide {
  0%,
  25% {
    transform: translateY(0%);
  }
  25%,
  50% {
    transform: translateY(-25%);
  }
  50%,
  75% {
    transform: translateY(-50%);
  }
  75%,
  100% {
    transform: translateY(-75%);
  }
}

.animate-slide {
  animation: slide 12s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 5000px;
  }
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: #000
    url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top
    center;
  z-index: 0;
}

.twinkling {
  background: transparent
    url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat
    top center;
  z-index: 1;
  animation: move-twink-back 200s linear infinite;
}

.aspect-w-4 {
  position: relative;
  padding-bottom: 125%; /* 5:4 aspect ratio */
}

.aspect-h-5 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Custom cursor styles */
.App {
  cursor: none;
}

/* This will show the default cursor on clickable elements */
a,
button,
input[type="submit"],
input[type="button"] {
  cursor: pointer;
}

/* Styles for the custom cursor component */
[data-component="CustomCursor"] {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

[data-component="CustomCursor"] img {
  position: relative;
}

[data-component="CustomCursor"] img::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: var(--trail-length);
  background-color: var(--trail-color);
  filter: blur(5px);
  opacity: 0.8;
}

.custom-cursor {
  position: fixed;
  width: 32px;
  height: 32px;
  pointer-events: none;
}

.planet {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #8ec38e;
  box-shadow: inset -8px -8px 16px rgba(0, 0, 0, 0.2),
    inset 8px 8px 16px rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.planet::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 8px 4px rgba(255, 255, 255, 0.8);
}

.ring {
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid rgba(142, 195, 142, 0.8);
  pointer-events: none;
  z-index: 9998;
  transition: transform 0.2s ease-out;
}

.custom-cursor:hover ~ .ring {
  transform: scale(1.6);
}

body,
a,
button {
  cursor: none;
}

.custom-cursor {
  pointer-events: none;
}
